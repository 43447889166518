import axios from "@/utils/axios.js";

import {serializedQuery} from "@/utils/UrlHelper.js";
import {$confirm, $dialog} from "@/utils/pluginDialog.js";
import {$t} from "@/i18n/useLanguage.js";

export default function LaravelPiniaPlugin(mergeState, getters, actions) {
  let state = {
    url: '',
    primaryKey: '',
    paginate: {
      current_page: 1,
      data: [],
      from: 1,
      last_page: 1,
      per_page: 14,
      to: 1,
      total: 0
    },
    model: {},
    query: {
      page: 1,
      sort: null,
      order: 'desc',
    },
    loading: false,
    // 서버에서 받아온 데이터가 캐시됩니다. 데이터를 생성, 수정, 삭제 할 때마다 캐시가 초기화 됩니다.
    cached: {
      activated: false,
      expire: 0,
    },
    config:{
      showMessage: true
    },
    isSSR: false
  };

  if (mergeState !== undefined) {
    state = Object.assign(state, mergeState);
  }

  let gettersType = {
    isLoading: (state) => {
      return state["loading"];
    },
    getModel: (state) => {
      return state["model"];
    },
    getData: (state) => {
      return state["paginate"]?.data;
    },
    getPagination: (state) => {
      return state['paginate'];
    }
  };

  if (getters !== undefined) {
    gettersType = Object.assign(gettersType, getters);
  }

  let cacheData;
  let actionsType = {
    asCached(activated = true) {
      this.cached.activated = activated;
      return this;
    },
    remember($callback, $second = 5) {
      this.cached.expire = Date.now() + ($second * 1000);
      this.cached.activated = true;
      if(this.cached.expire > Date.now()) {
        if(cacheData){
          return cacheData;
        }
      }
      cacheData = $callback();
      return cacheData;
    },
    showDialog(){
      this.config.showMessage = true;
    },
    hiddenDialog(){
      this.config.showMessage = false;
    },
    async cacheClear() {
      this.cached.expire = 0;
    },
    hydrate() {
      this.model = {};
      this.query = {};
      this.paginate = {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: 15,
        to: 1,
        total: 0
      };
    },
    removeQuery(key) {
      delete this.query[key];
      this.cached.expire = 0;
    },
    clearQuery(){
      this.query = {};
      this.cached.expire = 0;
      return this;
    },
    setQuery(query, clear = false) {
      if (clear) {
        this.query = {};
      }
      this.query = Object.assign(this.query, query);
      this.cached.expire = 0;
    },
    attachFile(file, field = 'files') {
      if (!this.model[field]) {
        this.model[field] = [];
      }
      this.model[field].push(file);

      return this;
    },
    async getPageIndex(num) {
      this.query.page = num;
      this.cached.expire = 0;
      await this.index();
    },
    async getMoreByPage() {
      if(!this.query.page){
        this.query.page = 1;
      }
      // 마지막 페이지라면 알럿주기
      if (this.paginate.current_page === this.paginate.last_page) {
        await $dialog($t('마지막 페이지 입니다.'), {
          title: $t('알림')
        });
        return;
      }
      this.query.page++;
      this.cached.expire = 0;
      await this.index(true);
    },
    async index(increment = false) {
      if (this.cached.activated && this.cached.expire > Date.now()) {
        return;
      }
      this.isSSR = typeof window === 'undefined';
      this.loading = true;
      try {
        const response = await axios.get(this.url + '?' + serializedQuery({...this.query}));
        if (!response){
          return;
        }
        const data = response.data;
        if (increment) {
          let oldData = this.paginate.data;
          data.data = oldData.concat(data.data);
          this.paginate = data;

        }else{
          this.paginate = data;
        }
      }catch (e){
        if(e?.response?.data?.message && this.config.showMessage){
          $dialog($t(e.response.data.message), {
            title: $t('에러')
          });
        }
        throw e;
      } finally {
        this.loading = false;
        this.cached.expire = Date.now() + (1000 * 60);
      }
    },
    async show(id, query={}) {
      this.loading = true;
      this.isSSR = typeof window === 'undefined';
      try {
        if (id === undefined)  {
          console.log( new Error('id 가 없습니다.') );
          return;
        }
        let string ='';
        if(Object.keys(query).length) {
          string = '?' + serializedQuery({ ...query})
        }
        const {data} = await axios.get(`${this.url}/${id}${string}` );

        this.model = data;
      }catch (e){
        if(e?.response?.data?.message && this.config.showMessage){
          $dialog($t(e.response.data.message), {
            title: $t('에러')
          });
        }
        throw e;
      } finally {
        this.loading = false;
        this.cached.expire = 0;
      }
    },
    async store(model) {
      this.loading = true;
      this.isSSR = typeof window === 'undefined';
      try {
        const {data} = await axios.post(this.url, model);
        this.model = data;
      }catch (e){
        if(e?.response?.data?.message && this.config.showMessage){
          $dialog($t(e.response.data.message), {
            title: $t('에러')
          });
        }
        throw e;
      } finally {
        this.loading = false;
        this.cached.expire = 0;
      }
    },
    async update(model) {
      this.loading = true;
      this.isSSR = typeof window === 'undefined';
      try {
        let key = model[this.primaryKey];
        if (key === undefined) {
            console.log( new Error('primaryKey 가 없습니다.') );
            return;
        }
        const {data} = await axios.put(`${this.url}/${key}`, {...model});
        this.model = data;
      }catch (e){
        if(e?.response?.data?.message && this.config.showMessage){
          $dialog($t(e.response.data.message), {
            title: $t('에러')
          });
        }
        throw e;
      } finally {
        this.loading = false;
        this.cached.expire = 0;
      }
    },
    /**
     *
     * @param files {File[]}
     * @param field {string}
     * @param method
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    async upload(files, field = 'files', method = 'post') {
      this.loading = true;
      this.isSSR = typeof window === 'undefined';

      const formData = new FormData();
      // model 에 있는 데이터를 formData 에 추가
      Object.keys(this.model).forEach((key) => {
        if(key === field){
          return;
        }
        formData.append(key, this.model[key]);
      });

      if(files){
        Array.from(files).forEach((file) => {
          formData.append(field + '[]', file);
        });
      }

      //fromData 에 있는 데이터를 확인
      let data = {};
      for (let key of formData.keys()) {
        data[key] = formData.get(key);
      }

      let url = this.url;

      try {
        if(method === 'put'){
          url = `${this.url}/${this.model[this.primaryKey]}`;
          return await axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-HTTP-Method-Override': 'PUT'
            }
          });
        }else{
          return await axios[method](url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        }
      }catch (e){
        if(e?.response?.data?.message && this.config.showMessage){
          $dialog($t(e.response.data.message), {
            title: $t('에러')
          });
        }
        throw e;
      } finally {
        this.loading = false;
        this.cached.expire = 0;
      }
    },
    async destroy(model, confirmMessage=null) {
      this.loading = true;
      this.isSSR = typeof window === 'undefined';
      if (confirmMessage) {
        try {
          await $confirm(confirmMessage, {
            title: $t('확인'),
            cancelLabel: $t('취소'),
          })

        } catch (e) {
          this.loading = false;

          throw e;
        }
      }

      try {
        let key = model[this.primaryKey];
        if (key === undefined) {
          new Error('primaryKey 가 없습니다.');

        }
        const {data} = await axios.delete(`${this.url}/${key}`, model);
        this.model = data;
        if(data?.message && this.config.showMessage){
          $dialog($t(data.message), {
            title: $t('확인')
          });
        }
      } catch (e) {

        if(e?.response?.data?.message && this.config.showMessage){
          $dialog($t(e.response.data.message), {
            title: $t('에러')
          });
        }
        throw e;
      } finally {

        this.loading = false;
        this.cached.expire = 0;

      }
    }
  };

  if (actions !== undefined) {
    actionsType = Object.assign(actionsType, actions);
  }

  return {
    state: state,
    getters: gettersType,
    actions: actionsType
  }
}
