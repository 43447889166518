<script setup>

import {$t} from "@/i18n/useLanguage.js";
import {computed} from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true
  },
  max: {
    type: Number,
    default: 0
  },
  showCount: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:value']);

let warning = false;

function inputControl(event) {
  let value = event.target.value;
  if(value.length > props.max && props.max > 0 && !warning){
    value = value.slice(0, props.max);
    warning = true;
  }else{
    warning = false;
  }
  emit('update:value', value);
}

const textCount = computed(() => {
  return props.value.length || 0;
});

</script>

<template>
  <textarea
    :value="value"
    :placeholder="$t('내용을 입력해주세요')"
    style="resize: vertical"
    :class="$attrs.class"
    class="text-base self-stretch min-h-[10rem] pl-5 pr-4 py-4 bg-stone-50 border-b border-stone-700 justify-start items-start gap-5 inline-flex outline-none"
    @input="inputControl"
  />
  <span
    v-if="max > 0"
    class="text-stone-500"
  >{{ value.length }} {{ (warning) ? $t('자 미만으로 작성해야 합니다.') : '' }}</span>
  <div
    v-if="showCount"
    class="text-stone-500 w-full text-right text-sm"
  >
    ( {{ textCount }} )
  </div>
</template>

<style scoped>

</style>
