<template>
  <div
    v-if="showModal"
    class="modal-transition fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center z-50"
    :style="{ opacity: opacity }"
    @click="closeModal"
  >
    <img
      :src="imageUrl"
      class="w-full object-cover lg:max-w-2xl max-w-[90%]"
    >
  </div>
  <img
    :src="imageUrl"
    :class="$attrs.class"
    class="object-cover rounded-xl cursor-pointer"
    @click="openModal"
  >
  <slot />
</template>

<script setup>
import { ref } from 'vue';

defineProps({
  imageUrl: {
    type: String,
    required: true
  }
});

const showModal = ref(false);
const opacity = ref(0);

const openModal = () => {
  showModal.value = true;
  setTimeout(() => {
    opacity.value = 1;
  }, 0);
};

const closeModal = () => {
  opacity.value = 0;
  setTimeout(() => {
    showModal.value = false;
  }, 500);
};
</script>
<style scoped>
.modal-transition {
  transition: opacity 0.5s ease-in-out;
}
</style>
