<template>
  <div class="w-full overflow-auto relative">
    <div class="flex gap-2">
      <div
        class="rounded-2xl w-[6rem] h-[6rem] bg-stone-100 p-4 cursor-pointer hover:border-blue-500 relative shrink-0"
        @drop="onDrop"
        @dragover.prevent
        @dragleave="dragging = false"
        @dragenter="dragging = true"
      >
        <input
          ref="fileInput"
          type="file"
          class="absolute top-0 left-0 opacity-0 w-full h-full"
          multiple
          @change="onFileChange"
        >
        <div
          v-if="dragging"
          class="bg-stone-700 opacity-50 absolute top-0 left-0 w-full h-full rounded-2xl"
        />
        <p class="text-center w-full flex items-center justify-center">
          <img
            src="/images/icons/plus.svg"
            class="w-8 h-auto py-4"
          >
        </p>
      </div>
      <!-- Image Preview -->
      <template v-if="imagePreviews.length">
        <div
          v-for="(preview,ix) in imagePreviews"
          :key="ix"
          class="relative bg-stone-50 w-[6rem] shrink-0 overflow-hidden overflow-ellipsis hover:overflow-visible whitespace-nowrap rounded-xl"
        >
          <span
            class="absolute top-1 right-1 cursor-pointer bg-stone-50 bg-opacity-90 rounded-full"
            @click="()=>{
              imagePreviews.splice(ix, 1)
              previewTitles.splice(ix, 1)
            }"
          >
            <img
              src="/images/icons/close.svg"
              class="w-4 h-auto"
            >
          </span>
          <image-modal
            v-if="!isNotImage(preview)"
            :key="ix"
            :image-url="preview"
            class="w-[6rem] h-[6rem] object-cover rounded-2xl"
            alt="Uploaded Preview"
          />
          <div
            v-else
            class="flex items-center justify-center h-full"
          >
            <FileIcon
              width="40"
              height="40"
            />
          </div>
          <span
            v-if="previewTitles[ix]"
            class="text-xs w-full px-1 absolute bottom-0 left-0 bg-stone-100"
          >{{ previewTitles[ix] }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ImageModal from "@/components/ui/ImageModal.vue";
import {FileIcon} from "lucide-vue-next";

export default {
  name: "IconicFileInput",
  components: {FileIcon, ImageModal},
  emits: ['update:value'],
  data() {
    return {
      dragging: false,
      imagePreviews: [],
      previewTitles: []
    };
  },
  methods: {
    onDrop(event) {
      this.dragging = false;
      const files = event.dataTransfer.files;
      if (files.length) {
        this.$refs.fileInput.files = files;
        this.onFileChange();
      }
    },
    getFiles() {
      return this.$refs.fileInput.files;
    },
    isNotImage(url){
      return url === '/images/icons/download.svg';
    },
    onFileChange() {
      const files = this.$refs.fileInput.files;

      Array.from(files).forEach(file => {
        const fileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
        if (fileTypes.includes(file.type)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreviews.push(e.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          this.imagePreviews.push('/images/icons/download.svg');
        }
        this.previewTitles.push(file.name);
      });
      this.$emit('update:value', files);
    },
    async imageChange(file) {
      if (file && file.size < 4 * 1024 * 1024) { // 3MB보다 큰 경우
        return file;
      }
      return await this.compressImage(file);
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);

          // JPEG 형식으로 이미지 품질을 0.6로 설정하여 이미지를 압축
          canvas.toBlob((blob) => {
            resolve(URL.createObjectURL(blob));
          }, 'image/jpeg', 0.6);
        };
        image.onerror = error => reject(error);
      });
    }
  },
};
</script>
