<template>
  <div
    title="footer"
    class="w-full pt-12 bg-stone-100 border-t border-stone-300 flex-col justify-start items-center gap-12 inline-flex"
  >
    <div class="self-stretch px-10 justify-start items-start gap-16 inline-flex">
      <div class="flex-col justify-start items-start gap-3 inline-flex">
        <div
          class="text-stone-700 text-base font-semibold leading-none"
          @click="()=>{
            router.push({
              name: 'CustomerCenter'
            })
          }"
        >
          {{ $t("고객센터") }}
        </div>
        <div class="justify-start items-center gap-3 inline-flex">
          <div class="text-stone-700 text-4xl font-normal leading-10">
            <a href="tel:18008914">1800-8914</a>
          </div>
          <div class="flex-col justify-start items-start gap-1 inline-flex">
            <div class="justify-start items-center gap-2 inline-flex">
              <div class="text-stone-700 text-base font-semibold leading-none">
                {{ $t("평일") }} 10:00~18:00
              </div>
              <div class="text-stone-300 text-base font-semibold leading-none">
                |
              </div>
              <div class="text-stone-700 text-base font-normal leading-none">
                {{ $t("점심시간") }} 12:00~13:00
              </div>
            </div>
            <div class="text-stone-700 text-base font-normal leading-none">
              {{ $t("주말, 공휴일 휴무") }}
            </div>
            <div class="text-stone-700 text-base font-normal underline leading-none">
              service@candleworks.com
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col justify-start items-start gap-3 inline-flex">
        <div class="text-stone-700 text-base font-semibold leading-none">
          Bank Account
        </div>
        <div class="flex-col justify-start items-start gap-1 flex">
          <div class="text-stone-700 text-base font-normal leading-none">
            {{ __("기업은행", "INDUSTRIAL BANK OF KOREA") }} <br> 614-020463-04-011
          </div>
          <div class="text-stone-700 text-base font-normal leading-none">
            {{ $t("크로마웍스 주식회사") }}
          </div>
        </div>
      </div>
      <div class="flex-col justify-start items-start gap-3 inline-flex">
        <div class="text-stone-700 text-base font-semibold leading-none">
          Offline Store
        </div>
        <small-round-button class="text-sm">
          <div
            class="flex flex-row justify-center items-center"
            @click="()=>goTo({name:'OfflineStore'})"
          >
            {{ $t('CW 오프라인 매장 살펴보기') }}
            <img
              src="/images/icons/chevron.svg"
              class="w-4 h-4"
            >
          </div>
        </small-round-button>
      </div>
      <div class="flex-col justify-start items-start gap-3 inline-flex">
        <div class="text-stone-700 text-base font-semibold leading-none">
          Social
        </div>
        <div class="justify-start items-center gap-3 inline-flex">
          <a
            href="https://www.instagram.com/candleworks_"
            target="_blank"
            class="w-7 h-7 relative"
          >
            <img
              alt=""
              src="/images/icons/sns-insta.svg"
            >
          </a>
          <a
            href="http://pf.kakao.com/_qKnjxl"
            target="_blank"
            class="w-7 h-7 relative"
          >
            <img
              alt=""
              src="/images/icons/sns-kakao.svg"
            >
          </a>
        </div>
      </div>
    </div>
    <div class="self-stretch h-px border border-stone-300" />
    <div class="self-stretch px-10 justify-start items-start gap-7 inline-flex">
      <div class="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex">
        <div class="self-stretch justify-start items-center gap-3 inline-flex">
          <div class="grow shrink basis-0 text-stone-700 text-base font-semibold leading-none">
            Notice
          </div>
        </div>
        <div class="flex-col justify-start items-start gap-2 flex">
          <div
            v-for="item in controller.getData"
            :key="item.id"
            class="justify-start items-start gap-3 inline-flex cursor-pointer"
            @click="()=>{ goTo({name:'CustomerNoticeShow', params:{id:item.id}}) }"
          >
            <div class="text-stone-700 text-base font-medium leading-none">
              {{ item.created_at.substring(0, 10) }}
            </div>
            <div class="text-stone-500 text-base font-normal leading-none">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="justify-start items-start gap-8 flex">
        <div class="w-48 px-5 border-l border-stone-300 flex-col justify-start items-start gap-3 inline-flex">
          <div class="text-stone-700 text-base font-semibold leading-none">
            My Order
          </div>
          <div class="flex-col justify-start items-start gap-2 flex">
            <router-link
              v-for="v in state.myorder"
              :key="v.label"
              :to="v.path"
            >
              <div class="text-stone-500 text-base font-normal leading-none">
                {{ $t(v.label) }}
              </div>
            </router-link>
          </div>
        </div>
        <div class="w-48 px-5 border-l border-stone-300 flex-col justify-start items-start gap-3 inline-flex">
          <div class="text-stone-700 text-base font-semibold leading-none">
            Customer Center
          </div>
          <div class="flex-col justify-start items-start gap-2 flex">
            <router-link
              v-for="v in state.customerCenter"
              :key="v.label"
              :to="v.path"
            >
              <div class="text-stone-500 text-base font-normal leading-none">
                {{ $t(v.label) }}
              </div>
            </router-link>
          </div>
        </div>
        <div class="w-48 px-5 border-l border-stone-300 flex-col justify-start items-start gap-3 inline-flex">
          <div class="text-stone-700 text-base font-semibold leading-none">
            Company
          </div>
          <div class="flex-col justify-start items-start gap-2 flex">
            <router-link
              v-for="v in state.company"
              :key="v.label"
              :to="v.path"
            >
              <div class="text-stone-500 text-base font-normal leading-none">
                {{ $t(v.label) }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="self-stretch h-32 p-10 bg-[#ECEBEA] flex-col justify-start items-start gap-3 flex">
      <div class="flex-col justify-start items-start gap-1 flex">
        <div class="justify-start items-center gap-3 inline-flex">
          <div
            v-for="v in state.bottom"
            :key="v"
            class="text-stone-700 text-sm font-medium leading-3"
          >
            {{ v }}
          </div>
          <div class="px-1 py-0.5 bg-stone-500 rounded-sm justify-end items-center flex">
            <a
              class="text-center text-stone-100 text-xs leading-3"
              href="https://mob.tbht.hometax.go.kr/jsonAction.do?actionId=UTBABAAA14F001&menuId=6001150200"
              target="_blank"
            >
              {{ $t("사업자정보확인") }}
            </a>
          </div>
        </div>
        <div class="justify-start items-start gap-3 inline-flex">
          <div class="text-stone-700 text-sm font-medium leading-3">
            {{ $t("사업장소재지: 경기도 부천시 산업로 124 캔들웍스하우스") }}
          </div>
          <div class="text-stone-700 text-sm font-medium leading-3">
            {{ $t("고객센터") }}: 1800-8914 / 032-672-8914 ({{ $t("토/일/공휴일 휴무") }})
          </div>
          <div class="text-stone-700 text-sm font-medium leading-3">
            service@candleworks.com
          </div>
        </div>
        <div class="text-stone-700 text-sm font-medium leading-3">
          © 2023 Candleworks All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router';
import {onMounted, onServerPrefetch, ref} from "vue";
import {$t, __} from "@/i18n/useLanguage.js";
import {useNoticeBoard} from "@/utils/useNoticeBoard.js";


const router = useRouter();
const emit = defineEmits(['moved']);
function goTo(url) {
  router.push(url);
  emit('moved', true);
}

const state = ref({
  myorder: [
    { label: '쇼핑몰 주문내역', path: '/my-page/order' },
    { label: '매장 구매내역', path: '/my-page/store-order' },
    { label: '강의 신청내역', path: '/my-page/tssa' },
    { label: '나의 문의', path: '/my-page/inquiry' },
    { label: '나의 리뷰', path: '/my-page/product-review' },
    { label: '배송료 안내', path: '/customer/shipment-cost' },
  ],
  customerCenter: [
    { label: '주문/배송문의', path: '/customer/center' },
    { label: '자주묻는질문', path: '/customer/faq' },
    { label: '도매신청', path: '/customer/wholesale-apply' },
    { label: '상품문의', path: '/customer/product-inquiry' },
    { label: '상품후기', path: '/customer/product-review' },
    { label: '서류자료실', path: '/customer/document-board' },
  ],
  company: [
    { label: '회사소개', path: '/contents/company' },
    { label: '매장안내', path: '/contents/store' },
    { label: '딜러신청', path: '/customer/dealer-apply' },
    { label: '이용약관', path: '/contents/privacy' },
    { label: '개인정보처리방침', path: '/contents/privacy' },
    { label: '공지사항', path: '/customer/notice' },
  ],
  bottom: [
    '상호명: 크로마웍스 주식회사',
    '대표자: 장혜경',
    '개인정보책임자: 김경철',
    '사업자등록번호: 130-87-01811',
    '통신판매업신고: 제2014-경기부천-1610호',
  ],
});

const controller = useNoticeBoard();

async function fetch() {
  controller.setQuery({
    page: 1,
    per_page: 5,
    sort: 'created_at',
    order: 'desc'
  });
  await controller.getPageIndex(1);
}

onMounted(async () => {
  if(!controller.isSSR) {
    await fetch();
  }
})

onServerPrefetch(async () => {
  await fetch();
})
</script>
